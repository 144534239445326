<template>
    <div class="supplier gray-bg">
        <div style="position: relative">
            <van-search
                    :placeholder="$t('搜索')"
                    readonly
                    @click="showSearch"
            />
            <!-- <div class="supplier-search">
              <div class="supplier-search-list">暂无更多</div>
            </div> -->
        </div>
        <van-list
                v-model="loading"
                :finished="finished"
                immediate-check
                :finished-text="$t('没有更多了')"
                :loading-text="$t('加载中')"
                @load="searchSup"
        >
            <div class="article-card" v-for="item in list" :key="item.id" @click="showMaster(item)">
                <div class="article-header">
                    {{item.company_en}}
                </div>
                <div class="article-body">
                    <div>{{$t('contact_tel')}}:{{item.tel?item.tel:'-'}}</div>
                    <div>{{$t('email')}}:{{item.email?item.email:'-'}}</div>
                </div>
            </div>
        </van-list>
        <transaction-search
                ref="search"
                flag="supplier"
                @search="onSearch"
        ></transaction-search>
        <van-popup
                v-model="show"
                position="bottom"
                custom-style="height: 30%;"
                @close="onClose"
                class="master-box"
        >
            <van-cell-group>
                <van-cell v-if="consumed"
                        :title="$t('发出询盘')"
                        @click="doEnquiry"
                        center
                />
                <van-cell
                        :title="$t('查看供应商')"
                        @click="showSupplier"
                        center
                />
            </van-cell-group>
        </van-popup>
        <supcompany ref="supcompany" :item="item" @search="onSearch" @consumeEve="onConsumed"></supcompany>
        <inquiry ref="inquiry" :item="item" @search="onSearch" ></inquiry>
    </div>
</template>

<script>
    import TransactionSearch from "../components/TransactionSearch.vue";
    import ShowSupplier from "../components/ShowSupplier";
    import Inquiry from "../components/Inquiry";

    export default {
        name: "Supplier",
        data() {
            return {
                list: [],
                item: {}, // 选中供应商数据
                activeName: "1",
                consumed:false,
                params: {
                    page: 1, // 页数
                    pro: "", // 产品
                    sup: "", // 供应商
                    cert_file: "", // 产品证书
                    spec_pha: "", //  规格属性
                    level: "", //  等级
                },
                loading: false,
                show:false,
                finished: false,
            };
        },
        components: {
            "transaction-search": TransactionSearch,
            "supcompany": ShowSupplier,
            inquiry: Inquiry,
        },
        beforeRouteLeave(to, from, next) {
            if (this.$refs.search.show) {
                this.$refs.search.toggleShow();
                next(false);
            }else if (this.$refs.inquiry.show) {
                this.$refs.inquiry.toggleShow();
                next(false);
            }else if (this.$refs.supcompany.show) {
                this.$refs.supcompany.toggleShow();
                next(false);
            } else {
                next();
            }
        },
        mounted() {
            this.searchSup();
        },
        methods: {
            onClose(){
                this.show = false;
            },
            onConsumed(obj){
                this.list = this.list.filter(
                    (item)=>{
                        if(item&&item.id==obj.id){
                            item.company_en = obj.company_en;
                            item.tel = obj.tel;
                            item.email = obj.email;
                            item.consumed = 1;
                        }
                        return item;
                    }
                );
            },
            showSearch() {
                // 显示搜索框
                this.$refs.search.toggleShow();
            },
            onSearch(val) {
                // 搜索返回结果
                val.page = 1;
                this.list = [];
                this.params = {...this.params, ...val};
                this.loading = true;
                this.searchSup();
            },
            searchSup() {
                // 查询供应商
                this.$http.post("/v1/searchSup", this.params).then((res) => {
                    if (res.code == 200) {
                        this.list = this.list.concat(res.data.list.data);
                        this.loading = false;
                        this.finished = res.data.list.data.length < res.data.list.per_page;
                        this.params.page += 1;
                    }
                });
            },
            showSupplier(){
                this.show = false;
                this.$refs.supcompany.sup_id = this.item.id;
                this.$refs.supcompany.sup = this.item.company_en;
                this.$refs.supcompany.toggleShow();
                //查看过供应商之后，将公司名称及邮箱电话解密，并能对其发出询盘sda

            },
            showMaster(obj){
                // obj.sup_name = obj.company_en;
                this.consumed = obj.consumed;
                this.item = obj;
                this.show = true;
            },
            doEnquiry() {
                this.$refs.inquiry.supValue = this.item.company_source;
                this.show = false;
                this.$refs.inquiry.toggleShow();
            },
        },
    };
</script>

<style scoped>
    .supplier {
    }

    .supplier-detail {
    }

    .supplier-detail-list {
        display: flex;
        margin-top: 5px;
    }

    .supplier-detail-title {
        width: 70px;
        text-align: right;
        flex-shrink: 0;
    }

    .van-tag {
        margin-right: 5px;
    }

    .supplier-detail-content {
    }

    .supplier-search {
        position: absolute;
        z-index: 1;
        left: 12px;
        top: 44px;
        width: calc(100vw - 24px);
        box-sizing: border-box;
        border: 1px solid #eee;
        background: white;
    }

    .supplier-search-list {
        padding: 10px 12px;
    }


    .gray-bg{
        background-color: #fafafa;
        min-height: 90vh;
    }
    .article-card{
        position: relative;
        box-sizing: border-box;
        padding: 8px 16px;
        color: #323233;
        font-size: 12px;
        background-color: #FFFFFF;
        margin: 5px 0px;
        min-height: 50px;
    }
    .article-body{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        line-height: 50px;
    }
    .article-body div{
        width: 50vw;
    }
    .van-cell__title{
        text-align: center;
    }
</style>